<template>
  <div class="app-container">
    <qzf-video vid="4467be537475a7579a5aa546ec540209_4"></qzf-video>

    <div class="top_steps">
      <el-steps :space="200" :active=status finish-status="success">
        <el-step title="上传数据" :icon="Upload"></el-step>
        <el-step title="科目初始化" :icon="Document"></el-step>
        <el-step title="确认数据" :icon="Tickets"></el-step>
        <!-- <el-step title="匹配科目" icon="el-icon-tickets"></el-step> -->
        <el-step title="完成" :icon="Success"></el-step>
      </el-steps>
    </div>



    <div style="position: absolute;top: 20px;left: 20px;">
    </div>
    
    <!-- 第一步 -->
    <div v-if="status == 0">
     
      
      <div class="import_box">
        <div class="drop">
          导入科目余额表
          <qzf-import-back @success="getProcessList()" @success2="otherBankDialog" from="科目" :comId="this.comId"></qzf-import-back>
        </div>
      </div>
      <!-- 无需初始化按钮 -->
      <!-- <div class="bottom_btns">
        <el-button @click="nextWu()" size="small" type="primary">无需初始化</el-button>
      </div> -->
    </div>
    <!-- 第二步 --> 
    <div v-if="status == 1">
      <div>
        <p style="color:red;font-size:15px">*请务必手动调整借贷方向,例如:进项税额</p>
        <el-table v-loading="loading" :data="list" border style="width: 100%;margin-top:20px;" height="600">
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column type="index" label="id">
          </el-table-column>
          <el-table-column label="原科目编码" min-width="200">
            <template #default="scope">
              <span>{{ scope.row.origSubjectCode }}</span>
            </template>
          </el-table-column>
          <el-table-column width="300px" label="原科目名称" min-width="300">
            <template #default="scope">
              <span v-if="scope.row.subjectCode.length != 4" :style="{ paddingLeft: scope.row.subjectCode.length/4*10 + 'px' }">{{ scope.row.origSubjectName }}</span>
              <span v-if="scope.row.subjectCode.length == 4">{{ scope.row.origSubjectName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="科目编码" min-width="200">
            <template #default="scope">
              <span>{{ scope.row.subjectCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="科目名称" min-width="300">
            <template #default="scope">
              <span v-if="scope.row.subjectCode.length != 4" :style="{ paddingLeft: scope.row.subjectCode.length/4*10 + 'px' }">{{ scope.row.subjectName }}</span>
              <span v-if="scope.row.subjectCode.length == 4">{{ scope.row.subjectName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="方向" min-width="140">
            <template #default="scope">
              <el-radio-group v-model="scope.row.type" @change="changeType(scope.row)" :disabled="scope.row.subjectCode.length == 4">
                <el-radio :label="1">借</el-radio>
                <el-radio :label="2">贷</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column label="异常提示" min-width="120">
            <template #default="scope">
              <span style="color:#ccc" v-if="scope.row.status == 1">已匹配科目</span>
              <span style="color:red" v-if="scope.row.type == 0">异常匹配科目</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="150" class-name="small-padding fixed-width">
            <template #default="scope">
              <div v-if="scope.row.subjectCode.length == 4">
                <el-button @click="handExceptions(scope.row,1)" type="primary" size="small">编辑</el-button>
                <el-button v-if="scope.row.type == 0" @click="handExceptions(scope.row,2)" type="info" size="small">移动</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-button style="margin-top:30px;" @click="againInitialization()" type="primary" size="small">重置</el-button>
      <el-button style="margin-top:30px;" v-loading.fullscreen.lock="fullscreenLoading" @click="nextclick()" type="primary" size="small">下一步</el-button>
    </div>
    <!-- 第三步 -->
    <div v-if="status == 2">
      
      <div >
        <el-table v-loading="loading" :data="list" border style="width: 100%;margin-top:20px;" height="600" size="small">
          <el-table-column type="index" label="id">
          </el-table-column>
          <el-table-column label="科目编码">
            <template #default="scope">
              <span>{{ scope.row.subjectCode }}</span>
            </template>
          </el-table-column>
          <el-table-column width="300px" label="科目名称">
            <template #default="scope">
              <span v-if="scope.row.subjectCode.length != 4" :style="{ paddingLeft: scope.row.subjectCode.length/4*10 + 'px' }">{{ scope.row.subjectName }}</span>
              <span v-if="scope.row.subjectCode.length == 4">{{ scope.row.subjectName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="方向">
            <template #default="scope">
              <span>{{ typeFilter(scope.row.type) }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="期初余额">
            <el-table-column label="借方(本位币)" width="120">
              <template #default="scope">
                <div class="box-content" v-if="scope.row.edit == 2">
                  <el-input value="number" v-model="scope.row.periodBeginIn" size="small"></el-input>
                </div>
                <span v-else>{{scope.row.periodBeginIn}}</span>
              </template>
            </el-table-column>
            <el-table-column label="贷方(本位币)" width="120">
              <template #default="scope">

                <div class="box-content" v-if="scope.row.edit == 2">
                  <el-input value="number" v-model="scope.row.periodBeginOut" size="small"></el-input>
                </div>
                <span v-else>{{scope.row.periodBeginOut}}</span>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column align="center" label="本期发生额">
            <el-table-column label="借方(本位币)" width="120">
              <template #default="scope">
                <div class="box-content" v-if="scope.row.edit == 2">
                  <el-input value="number" v-model="scope.row.periodInt" size="small"></el-input>
                </div>
                <span v-else>{{scope.row.periodInt}}</span>
              </template>
            </el-table-column>
            <el-table-column label="贷方(本位币)" width="120">
              <template #default="scope">
                <div class="box-content" v-if="scope.row.edit == 2">
                  <el-input value="number" v-model="scope.row.periodOut" size="small"></el-input>
                </div>
                <span v-else>{{scope.row.periodOut}}</span>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column align="center" label="本年累计发生额">
            <el-table-column label="借方(本位币)" width="120">
              <template #default="scope">
                <div class="box-content" v-if="scope.row.edit == 2">
                  <el-input value="number" v-model="scope.row.yearInt" size="small"></el-input>
                </div>
                <span v-else>{{scope.row.yearInt}}</span>
              </template>
            </el-table-column>
            <el-table-column label="贷方(本位币)" width="120">
              <template #default="scope">
                <div class="box-content" v-if="scope.row.edit == 2">
                  <el-input value="number" v-model="scope.row.yearOut" size="small"></el-input>
                </div>
                <span v-else>{{scope.row.yearOut}}</span>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column align="center" label="期末余额">
            <el-table-column label="借方(本位币)" width="120">
              <template #default="scope">
                <div class="box-content" v-if="scope.row.edit == 2">
                  <el-input value="number" v-model="scope.row.periodEndIn" size="small"></el-input>
                </div>
                <span v-else>{{scope.row.periodEndIn}}</span>
              </template>
            </el-table-column>
            <el-table-column label="贷方(本位币)" width="120">
              <template #default="scope">
                <div class="box-content" v-if="scope.row.edit == 2">
                  <el-input value="number" v-model="scope.row.periodEndOut" size="small"></el-input>
                </div>
                <span v-else>{{scope.row.periodEndOut}}</span>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column label="操作" align="center" min-width="150" class-name="small-padding fixed-width">
            <template #default="scope">
              <div>
                <!-- <el-button v-if="scope.row.edit == 3" type="primary" disabled size="small">不可编辑</el-button> -->
                <el-button v-if="scope.row.edit == 1" @click="edit(scope.row,scope.$index,2)" type="primary" size="small">编辑</el-button>
                <el-button v-if="scope.row.edit == 2" @click="edit(scope.row,scope.$index,1)" type="success" size="small">保存</el-button>
                <el-button v-if="scope.row.edit == 3" @click="edit(scope.row,scope.$index,2)" type="primary" size="small">编辑</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="filter-container clearfix">
        <div class="balance_box">
          <h6 style="color:green" v-if="trialBalanceStatus">试算平衡</h6>
          <h6 style="color:red" v-else>试算不平衡</h6>
          <div class="balance_font">
            <p>期初(借)：{{beginInTotal}}、期初(贷)：{{beginOutTotal}}</p>
            <p>本期(借)：{{periodInTotal}}、本期(贷)：{{periodOutTotal}}</p>
            <p>本年(借)：{{yearInTotal}}、本年(贷)：{{yearOutTotal}}</p>
            <p>期末(借)：{{endInTotal}}、期末(贷)：{{endOutTotal}}</p>
          </div>
        </div>
        <div class="right_btns">
          <el-button @click="againInitialization()" type="primary" size="small"  v-loading.fullscreen.lock="fullscreenLoading">重置</el-button>
          <el-button v-loading.fullscreen.lock="fullscreenLoading" @click="successclick()" type="primary" size="small">完成</el-button>
        </div>
      </div>
    </div>
    <!-- 第四步 -->
    <!-- <div v-if="status == 3">
      <p class="top_p">温馨提示：小规模纳税人可不对进项税额和未认证进项税进行匹配</p>
      <matchingSubjects @success="successNext()" :comId=comId></matchingSubjects>
    </div> -->
    <!--第五步 -->
    <div v-if="status == 3" class="center_btns">
      <el-button @click="againInitialization()" type="primary" plain size="small"  v-loading.fullscreen.lock="fullscreenLoading">重置</el-button>
      <el-button @click="goback()" type="primary" size="small">已完成</el-button>
    </div>
    <!-- 编辑科目弹窗 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogEdit" title="分配科目" class="button_bg">
      <el-form ref="dataForm" :rules="rules" :model="tempEdit" label-position="right" label-width="110px">
        <el-form-item label="选择上级科目:" prop="supCode">
          <el-select v-model="tempEdit.supCode" filterable placeholder="请选择科目" size="small" clearable @change="changeSubject()" style="width:230px;">
            <el-option v-for="item in sublist" :key="item.code" :label="item.code+'-'+item.name" :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科目名称:" prop="subjectName">
          <el-input style="width:230px;" v-model="tempEdit.subjectName" placeholder="请输入名称" size="small"></el-input>
        </el-form-item>

        <el-form-item label="借贷方向:" prop="type">
          <el-radio-group v-model="tempEdit.type" disabled>
            <el-radio :label="1">借</el-radio>
            <el-radio :label="2">贷</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="saveEdit()" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 移动弹窗 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogPvVisible" title="移动科目" class="button_bg">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="110px">
        <el-form-item label="选择科目:" prop="supCode">
          <el-select v-model="temp.code" filterable placeholder="请选择科目" size="small">
            <el-option v-for="item in sublist" :key="item.code" :label="item.code+'-'+item.name" :value="item.code+'-'+item.name">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="saveAdd()" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 导入其他的弹框 -->
    <importSubject @success="currencySuccess" ref="tongyong" v-loading.fullscreen.lock="fullscreenLoading"></importSubject>
  </div>
</template>

<script>
import importSubject from './components/importSubject.vue' //科目导入
import UploadExcelComponent from "@/components/import/son/index2.vue"; // 点击导入
// import matchingSubjects from "@/components/matchingSubjects/index.vue"; // 匹配科目组件
// import {bankCom,saveCommonlyUsed } from "@/api/ea"; 
import { buildSubjectOldList, buildSubjectSaveOne,buildSubjectSaveOneList,eaSubjectProcessOne,eaSubjectProcessSave,eaSubjectProcessDeleAll,buildSubjectSaveTwo,buildSubjectSaveThree,buildSubjectMove,buildSubjectDirection,buildSubjectEdit } from "@/api/build";
export default {
  name: "subject_balance_uploadExcel_new",
  components: { importSubject ,UploadExcelComponent },
  filters: {
    typefif(e) {
      if (e == 1) return "借";
      if (e == 2) return "贷";
      else return "请选择";
    }
  },
  data() {
    return {
      status: 0, // 4步到哪一步
      typeCut:'导入其他',
      list: [],
      listall2: [],
      listall: [],
      fullscreenLoading:false,
      dialogPvVisible:false,
      temp: {
        id: 0,
        code: "",
        comId:0,
      },
      tempEdit:{
        id: 0,
        supCode: "",
        subjectName: "",
        type: 2,
        comId:0,
      },
      rules: {
        code: [
          { required: true, message: "请选择科目", trigger: "change" }
        ]
      },
      beginInTotal: 0,
      beginOutTotal: 0,
      periodInTotal: 0,
      periodOutTotal: 0,
      yearInTotal: 0,
      yearOutTotal: 0,
      endInTotal: 0,
      endOutTotal: 0,
      sublist:[],
      trialBalanceStatus: true,
      comId:0,
      showtitle: false,
      historyList:[],
      bankName:'',
      dialogEdit:false,
      editArr:[],
      urls: [{
        value: 'https://etax.qizhangfang.net/template/科目余额表.xlsx',
        label: '一般纳税人科目余额表模板'
      }, {
        value: 'https://etax.qizhangfang.net/template/科目余额表2.xlsx',
        label: '小规模纳税人科目余额表模板'
      }],
      value:''
    };
  },
  created() {
    let id = this.$store.getters["commons/params"].addCustomerComId
    this.comId = id
    this.init();
  },
  methods: {
    caiji(){
      buildSubjectOldList({comId: this.comId}).then(res=>{
        if(res.data.msg == "success" && res.data.data.list.length != 0 ){
          this.otherBankDialog(res.data.data.list)
        }
      })
    },
    // 下载模板
    loadValue(e){
      if(e == ''){
        this.$message({
          message: "请选择模板",
          type: "warning"
        });
        return
      } else {
        window.open(this.value)
      }
    },
    // 展开
    showbox() {
      this.showtitle = true;
    },
    // 收起
    showbox2() {
      this.showtitle = false;
    },
    // 获取历史科目名称
    getHistoryList() {
      bankCom({type:2}).then(res=>{
        this.historyList = res.data.data.list
      })
    },
    // 保存历史名称
    saveHistory(e){
      saveCommonlyUsed({bankName: e,type:2}).then(res => {
      });
    },
    // 导入前
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning"
      });
      return false;
    },
    // 导入数据
    // handleSuccess({ results, header,header2 }){
    //   console.log(this.typeCut,99999)
    //   // this.saveHistory(this.typeCut)
    //   if(this.typeCut == "导入其他"){
    //     console.log("导入其他")
    //     this.otherExcele({ results, header, header2 })
    //   }
    // },
    // // 导入其他
    // otherExcele({ header2 }){
    //   this.$refs.tongyong.dialogFormVisible = true
    //   this.$refs.tongyong.init(header2)
    // },
    // 导入其他确定
    currencySuccess(data){
      console.log(data)
      this.saveSubject(data)
    },
    saveSubject(data){
      this.fullscreenLoading = true
      data.arr.map(v=>{
        v.origSubjectName = v.origSubjectName.replace(/\[(.+?)\]/g, '')
      })
      let param = {
        items: data.arr,
        comId: this.comId,
        num: data.temp.len.map(Number),
        speName:data.temp.nameSpe,
        spe:data.temp.spe
      }
      buildSubjectSaveOne(param).then(res => {
        if(res.data.msg == 'success'){
          this.fullscreenLoading = false
          this.loading = false;
          this.getList();
          this.$alert("成功", "导入成功", {
            confirmButtonText: "确定",
            type: 'success'
          });
          this.getList2();
          this.saveProcess(2);
          this.status = 1;
          // this.$store.dispatch("delCachedViewByName", 'addCustomer');
        } else {
          this.fullscreenLoading = false
        }
      });
    },
    init(){
      this.getParam();
    },
    getParam() {
      eaSubjectProcessOne({comId: this.comId}).then(res => {
        this.pid = res.data.data.process.id;
        if (res.data.data.process.id == 0) {
          //说明是新的
          this.saveProcess(1);
        } else {
          this.status = res.data.data.process.status - 1;
          this.getList2();
        }
        if(this.status == 0){
          this.caiji()
        }
      });
    },
    // 保存状态
    saveProcess(status) {
      eaSubjectProcessSave({ id: this.pid, status: status,comId: this.comId }).then(res => {

      });
    },
    // getList
    getList() {
      buildSubjectSaveOneList({comId: this.comId}).then(res => {
        if(res){
          this.list = res.data.data.list;
          this.sublist = res.data.data.subjectAll;
        }
        this.trialBalance()
      });
    },
    getList2() {
      buildSubjectSaveOneList({comId: this.comId}).then(res => {
        let listinit = res.data.data.list;
        listinit.map(z => {
          let arr = []
          let lens = z.subjectCode.length
          listinit.map(v=>{
            if(v.subjectCode.substring(0,lens) == z.subjectCode){
              arr.push(1)
            }
          })
          if(arr.length > 1) {
            z.edit = 3;
          }else{
            z.edit = 1;
          }
        });
        this.list = listinit;
        this.sublist = res.data.data.subjectAll;
        this.trialBalance()//试算平衡
      });
    },
    // 修改借贷方向
    changeType(item){
      buildSubjectDirection({id:item.id,type:item.type}).then(res => {
      });
    },
    // 下一步
    nextclick() {
      //判断科目是否重复 start
      let stop = false
      let code = ""
      let name = ""
      let cfarray = []
      this.list.map(v=>{
        let num = 0
        this.list.map(vv=>{
          if(v.subjectCode == vv.subjectCode){
            num++
          }
        })
        if(num > 1){
          console.log(v.subjectCode)
          code = v.subjectCode
          name = v.subjectName
          if(cfarray.indexOf(code + "-" + name) < 0){
            cfarray.push(code + "-" + name)
          }
          stop = true
        }
      })
      let cfstr = '' 
      cfarray.map(v=>{
        cfstr += `<p>${v}</p>`
      })
      if(stop){
        this.$alert(cfstr, '科目重复', {
          dangerouslyUseHTMLString: true
        });
        return
      }
      //end
      let status = false;
      this.list.map(v => {
        if (v.type == 0) {
          status = true;
        }
      });
      if (status) {
        this.$alert("请先解决异常科目", {
          confirmButtonText: "确定",
          type:"warning"
        });
        return false;
      }

      let status2 = false;
      this.list.map(v => {
        if (!v.subjectCode) {
          status2 = true;
        }
      });
      if (status2) {
        this.$alert("包含空科目请检查科目余额表重新导入", {
          confirmButtonText: "确定",
          type:"warning"
        });
        return false;
      }

      this.$confirm("借贷方向是否核对完成", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.status = 2;
        this.fullscreenLoading = true
        buildSubjectSaveTwo({comId:this.comId}).then(res => {
          this.fullscreenLoading = false
          if(res.data.msg != 'success'){
            return
          }
          this.saveProcess(3);
          this.getList2();
          this.trialBalance()
        });
      })
    },
    // 确认数据编辑数据
    edit(row, index, type) {
      console.log(row)
      let lens = row.subjectCode.length
      let arr = []
      // this.saveMoney(row.subjectCode)
      this.list[index].edit = type;
      this.trialBalance()//试算平衡
    },
    saveMoney(subjectCode) {
      // let nums = (subjectCode.length - 4)/2
      let parentCode = subjectCode.substring(0,subjectCode.length-2);
      this.list.map(v=>{
        if(v.subjectCode == parentCode){
          let periodBeginIn = 0
          let periodBeginOut = 0
          let periodEndIn = 0
          let periodEndOut = 0
          let periodInt = 0
          let periodOut = 0
          let yearInt = 0
          let yearOut = 0

          this.list.map(item=>{
            if(item.subjectCode.substring(0,item.subjectCode.length-2) == parentCode){
              periodBeginIn += Number(item.periodBeginIn)
              periodBeginOut += Number(item.periodBeginOut)
              periodEndIn += Number(item.periodEndIn)
              periodEndOut += Number(item.periodEndOut)
              periodInt += Number(item.periodInt)
              periodOut += Number(item.periodOut)
              yearInt += Number(item.yearInt)
              yearOut += Number(item.yearOut)
            }
          })
          v.periodBeginIn = periodBeginIn.toFixed(2)
          v.periodBeginOut = periodBeginOut.toFixed(2)
          v.periodEndIn = periodEndIn.toFixed(2)
          v.periodEndOut = periodEndOut.toFixed(2)
          v.periodInt = periodInt.toFixed(2)
          v.periodOut = periodOut.toFixed(2)
          v.yearInt = yearInt.toFixed(2)
          v.yearOut = yearOut.toFixed(2)
        }
      })
      if(parentCode.length > 4) {
        this.saveMoney(parentCode)
      }
    },
    // 试算平衡
    trialBalance() {
      let beginIn = 0
      let beginOut = 0
      let endIn = 0
      let endOut = 0
      let periodIn = 0
      let periodOut = 0
      let yearIn = 0
      let yearOut = 0
      this.list.map(v=>{
        if(v.subjectCode.length == 4){
          beginIn += v.periodBeginIn * 1
          beginOut += v.periodBeginOut * 1
          endIn += v.periodEndIn * 1
          endOut += v.periodEndOut * 1
          periodIn += v.periodInt * 1
          periodOut += v.periodOut * 1
          yearIn += v.yearInt * 1
          yearOut += v.yearOut * 1
        }
      })
      this.beginInTotal = beginIn.toFixed(2)*1
      this.beginOutTotal = beginOut.toFixed(2)*1
      this.endInTotal = endIn.toFixed(2)*1
      this.endOutTotal = endOut.toFixed(2)*1
      this.periodInTotal = periodIn.toFixed(2)*1
      this.periodOutTotal = periodOut.toFixed(2)*1
      this.yearInTotal = yearIn.toFixed(2)*1
      this.yearOutTotal = yearOut.toFixed(2)*1
      if(beginIn.toFixed(2)*1 != beginOut.toFixed(2)*1 || endIn.toFixed(2)*1 != endOut.toFixed(2)*1 || periodIn.toFixed(2)*1 != periodOut.toFixed(2)*1 || yearIn.toFixed(2)*1 != yearOut.toFixed(2)*1) {
        this.trialBalanceStatus = false
      }else {
        this.trialBalanceStatus = true
      }
    },
    // 移动科目
    handExceptions(row,type) {
      if(type == 1){
        this.disabled = false
        this.tempEdit.type = row.type
        this.tempEdit.id = row.id;
        this.tempEdit.supCode = '';
        this.tempEdit.subjectName = row.subjectName;
        this.dialogEdit = true;
      }else{
        this.temp.id = row.id;
        this.temp.code = '';
        this.dialogPvVisible = true;
      }
    },
    // 编辑科目自动带科目
    changeSubject(){
      this.editArr.map(v=>{
        if(this.tempEdit.supCode == v.code ){
          this.tempEdit.type = v.type
        }
      })
    },
    // 移动科目保存
    saveAdd() {
      if(!this.temp.code){
        this.$message.error('请选择科目');
        return
      }
      this.temp.code = this.temp.code.split("-")[0];
      this.temp.comId = this.comId;
      // this.temp.type = this.temp.type;
      buildSubjectMove(this.temp).then((res) => {
        this.dialogPvVisible = false;
        if(res.data.msg != 'success'){
          return
        }
        this.getList2();
        this.$notify({
          title: "成功",
          message: "修改成功",
          type: "success",
          duration: 2000
        });
      });
    },
    // 编辑科目保存
    saveEdit() {
          this.tempEdit.type = this.tempEdit.type * 1;
          this.tempEdit.comId = this.comId;
          buildSubjectEdit(this.tempEdit).then((res) => {
            if(res.data.msg != 'success'){
              return
            }
            // this.list.unshift(this.temp)
            this.getList2();
            this.dialogEdit = false;
            this.$notify({
              title: "成功",
              message: "修改成功",
              type: "success",
              duration: 2000
            });
          });
        // }
      // }
      // );
    },
    // 第三步完成
    successclick() {
      if(!this.trialBalanceStatus) {
        this.$message.error('试算不平，请进行调账');
        return
      }
      // this.loading = true;
      this.fullscreenLoading = true;
      // console.log(this.list)
      let param = this.list;
      param.map(v => {
        v.periodBeginIn = Number(v.periodBeginIn);
        v.periodBeginOut = Number(v.periodBeginOut);
        v.periodEndIn = Number(v.periodEndIn);
        v.periodEndOut = Number(v.periodEndOut);
        v.yearInt = Number(v.yearInt);
        v.yearOut = Number(v.yearOut);
        v.periodInt = Number(v.periodInt);
        v.periodOut = Number(v.periodOut);
      });
      let statuus = false
      let status1001 = false
      param.map(v => {
        if(v.subjectCode.substring(0,1) == '5'){
          if(v.periodBeginIn - v.periodBeginOut + v.periodInt - v.periodOut != 0){
            statuus = true
          }
        }
        if(v.subjectCode.match('1001.')){
          status1001 = true
        }
      });
      if(statuus){
        this.fullscreenLoading = false;
        this.$message.error('损益类科目有数，不允许建账');
        return
      }
      if(status1001){
        this.fullscreenLoading = false;
        this.$message.error('库存现金不允许下设下级，请删除科目余额表库存现金下级再进行导入');
        return
      }
      //验证不是一级科目是否有上级
      let statusCode = []
      param.map(v => {
        if(v.subjectCode.length != '4'){
          let statusUp2 = true
          param.map(z => {
            if(v.subjectCode.substring(0,v.subjectCode.lastIndexOf('.')) == z.subjectCode){
              statusUp2 = false
            }
          });
          if(statusUp2){
            statusCode.push(v.subjectCode)
          }
        }
      });
      if(statusCode.length != 0){
        this.fullscreenLoading = false;
        this.$message.error(statusCode.join("#") + '科目无上级科目，请重新建账');
        return
      }
      // 判断下级加起来是否等于上级
      let str = "" 
      param.map(v=>{
        let codeStr = this.findDirectSubordinateAmount(v, param);
        if (codeStr != "") {
          str = codeStr
        }
      })
      if(str != ""){
        this.fullscreenLoading = false
        this.$message.error(str + "下级余额不等于此科目");
        return
      }
      buildSubjectSaveThree({items:param,comId:this.comId}).then(res => {
        this.fullscreenLoading = false;
        if(res.data.msg != 'success'){
          return
        }
        this.saveProcess(4);
        this.status = 3;
        this.$bus.emit('addCustomerUpdate')
      });
    },
    // 科目限制
    findDirectSubordinateAmount(codeBalance,subjects){
      let code = codeBalance.subjectCode
      let codeLen = this.SubjectLens(code)
      let status = false // 是否存在下级
      let amountBeginIn = 0.0
      let amountBeginOut = 0.0
      let amountYearIn = 0.0
      let amountYearOut = 0.0
      subjects.map(subject=>{
        if(subject.subjectCode.length > code.length && (codeLen+1) == this.SubjectLens(subject.subjectCode) && code + "." == subject.subjectCode.slice(0,code.length + 1)){
          if(code == "2241"){
            console.log(subject.subjectCode)
          }
          status = true
          amountBeginIn = (amountBeginIn*1 + subject.periodBeginIn*1).toFixed(2)
          amountBeginOut = (amountBeginOut*1 + subject.periodBeginOut*1).toFixed(2)
          amountYearIn = (amountYearIn*1 + subject.yearInt*1).toFixed(2)
          amountYearOut = (amountYearOut*1 + subject.yearOut*1).toFixed(2)
        }
      })
      if (status) {
        // if (amountBeginIn != 0 && amountBeginOut != 0) {
        if (codeBalance.periodBeginIn != 0) {
          amountBeginIn = (amountBeginIn*1 - amountBeginOut*1).toFixed(2)
          amountBeginOut = 0
        } else {
          amountBeginOut = (amountBeginOut*1 - amountBeginIn*1).toFixed(2)
          amountBeginIn = 0
        }
        // }
        if(code == "5602"){
          console.log(codeBalance.periodBeginIn, amountBeginIn)
          console.log(codeBalance.periodBeginOut, amountBeginOut)
          console.log(codeBalance.yearInt, amountYearIn)
          console.log(codeBalance.yearOut, amountYearOut)
        }
        if (codeBalance.periodBeginIn != amountBeginIn || codeBalance.periodBeginOut != amountBeginOut || codeBalance.yearInt != amountYearIn || codeBalance.yearOut != amountYearOut) {
          return codeBalance.subjectCode
        }
      }
      return ""
    },
    SubjectLens(code){
      return code.split('.').length
    },
    // 匹配成功后
    successNext(){
      this.saveProcess(5);
      this.status = 4;
    },
    // 重置
    againInitialization() {
      this.$confirm("此操作清除之前所有数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.fullscreenLoading = true
        eaSubjectProcessDeleAll({comId: this.comId}).then(res => {
          if(res.data.msg == 'success'){
            this.goback();
            this.status = 0;
          }
          this.fullscreenLoading = false
        });
      })
    },
    // 返回上一个页面
    goback() {
      this.$store.dispatch("tagsView/delViewByName", 'subject_balance_uploadExcel_new') // 关闭本页面
      this.$bus.emit('addCustomerUpdate')
      this.$router.go(-1)
    },
    // 无需初始化
    nextWu() {
      this.getList2();
      this.status = 1;
      this.saveProcess(2);
    },
    otherBankDialog(e){
      this.$refs.tongyong.dialogFormVisible = true
      this.$refs.tongyong.init(e)
    },
    // 导入之后
    getProcessList(){
      this.getList2();
      this.saveProcess(2);
      this.status = 1;
    },
    typeFilter(type){
      if(type == 1){
        return "借"
      }else if(type == 2){
        return "贷"
      }
    },
  }
}
</script>
<style>
.main_item .el-radio-button--medium:nth-child(5n) .el-radio-button__inner{
  border-right: 1px solid #dcdfe6;
}
.main_item .el-radio-button--medium .el-radio-button__inner{
   border: 0;
   border-right: 0.5px solid #dcdfe6;
   border-bottom: 0.5px solid #dcdfe6;
}
.main_item .el-radio-button--medium  {
  border: 0px solid #dcdfe6;
}
.content_main .el-popover, .el-radio-button:first-child:last-child .el-radio-button__inner{
  border-radius: 0 !important;
}
</style>
<style lang="scss" scoped>
.top_p{
  font-size: 14px;
  color: red;
}
.top_steps{
  width: 701px;
  margin: 0 auto;
  margin-bottom:30px ;
}
.el-radio-button--medium    .el-radio-button__inner{
  padding: 10px;
  width: 140px;
  border-radius: 0px;
}
.content_main .el-radio-group{
  width:701px;
  margin:0 auto;
  margin-top:20px;
  border: 1px solid #dcdfe6;
  border-bottom: transparent;
  border-right: transparent;
}
.main_box{
  width: 701px;
  margin:0 auto;
  p{
    font-size: 14px;
    color: #F15A24;
    margin-top: 20px;
  }
    .el-radio-button__inner{
    position: relative;
    i{
      position: absolute;
      right: -8px;
      top: -8px;
      font-size: 16px;
      color: #f56c6c;
      cursor: pointer;
      z-index: 9999;
    }
  }
}
.filter-container{
  width: 100%;
  margin-top: 20px;
  .balance_box{
    float: left;
    width: 50%;
    h6{
      font-size: 22px;
      font-weight: normal;
      line-height: 30px;
    }
    .balance_font{
      p{
        font-size: 15px;
        color: #333;
        line-height: 24px;
      }
      
    }
  }
  .right_btns{
    width: 20%;
    float: right;
    text-align: right;
  }
}
.show_box{
  position: relative;
    .el-radio-group{
    overflow: hidden;
  }
  .amod_ad {
    animation: ad 0.3s 0s both;
  }
  .amod_more {
    animation: more 0.3s 0s both;
  }
  .amod {
    height:176px;
  }
  @-webkit-keyframes ad {
    0% {
      height: 666px;
    }

    100% {
      height: 176px;
    }
  }
  @-webkit-keyframes more {
    0% {
      height: 176px;
    }

    100% {
      height: 666px;
    }
  }
  .more {

    width: 60px;
    line-height: 30px;
    height: 30px;
    transition: 0.3s;
    color: #666;
    position: absolute;
    bottom: -30px;
    left: 0;
    background: #eee;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    p{
      margin: 0 !important;
      line-height: 30px;
    }
  }
}
.bottom_btns{
  width: 701px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.import_box{
  position: relative;
  /* margin-top: 340px; */
  margin-top: 40px;
}
.excel-upload-input{
  display: none;
  z-index: -9999;
}
.drop{
  border: 2px dashed #bbb;
  width: 701px;
  height: 160px;
  // line-height: 160px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  // text-align: center;
  color: #bbb;
  position: relative;
}
</style>

<style lang="scss">
.el-step__title {
  font-size: 14px;
  font-weight: 500;
}
.el-step__title.is-process{
  font-size: 14px;
  font-weight: 500 !important;
}
</style>